import { graphql, useStaticQuery } from "gatsby";

export const useSite = () => {
    const data = useStaticQuery(graphql`
        query {
            sanitySite {
                title
                siteUrl
                phone
                email
                address
                socialMediaLinks {
                    whatsapp
                    twitter
                    linkedIn
                    instagram
                    facebook
                }
            }
        }
    `);

    return data.sanitySite;
};
