import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Disclosure } from "@headlessui/react";
import { ThemeToggler } from "gatsby-plugin-dark-mode";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { BsFillMoonFill, BsFillBrightnessHighFill } from "react-icons/bs";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "gatsby";
import { useSite } from "../../hooks/use-site";
import Logo from "../../images/Logo1.svg";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const navigation = [
    { name: "Home", link: "/" },
    { name: "services", link: "/services" },
    { name: "careers", link: "/careers" },
    { name: "contact", link: "/contact" },
];

export default function Navbar() {
    const [darkMode, setDarkMode] = useState(false);
    const site = useSite();

    useEffect(() => {
        console.log(localStorage.getItem("theme"));
        setDarkMode(localStorage.getItem("theme") === "dark");
    }, []);

    return (
        <Disclosure as="nav">
            {({ open, close }) => (
                <>
                    <div className="mx-auto max-w-7xl px-5 sm:px-7 lg:px-8">
                        <div className="relative flex py-4 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="inline-flex items-center justify-center rounded-md py-2 text-primary hover:text-primary/50">
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    {open ? (
                                        <XMarkIcon
                                            className="block h-8 w-8"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <Bars3Icon
                                            className="block h-8 w-8"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex flex-1 items-right justify-center sm:items-stretch sm:justify-between">
                                <Link
                                    to={"/"}
                                    className="flex flex-shrink-0 items-center font-norican text-4xl text-primary dark:text-primary"
                                >
                                    <img
                                        className="w-[35px] h-[39px] md:w-[40px] md:h-[44px] object-contain"
                                        src={Logo}
                                        alt="Logo"
                                    />
                                </Link>
                                <div className="hidden sm:ml-6 sm:block">
                                    <div className="flex space-x-3 text-sm lg:text-base">
                                        {navigation.map((item) => (
                                            <Link
                                                key={item.name}
                                                to={item.link}
                                                className={classNames(
                                                    item.current
                                                        ? "text-primary"
                                                        : "text-primary hover:text-black/50 dark:hover:text-gray-200",
                                                    "px-2 text-base lg:px-3 py-5 rounded-md  font-semibold capitalize"
                                                )}
                                                aria-current={
                                                    item.current
                                                        ? "page"
                                                        : undefined
                                                }
                                            >
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 hidden md:flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                <div className="flex justify-center items-center">
                                    <ThemeToggler>
                                        {({ theme, toggleTheme }) => {
                                            return (
                                                <button
                                                    className="mx-4 text-primary hover:text-black/50 dark:hover:text-gray-200"
                                                    type="button"
                                                    onClick={() => {
                                                        toggleTheme(
                                                            darkMode
                                                                ? "light"
                                                                : "dark"
                                                        );
                                                        setDarkMode(!darkMode);
                                                    }}
                                                    aria-label="Toggle theme"
                                                >
                                                    {!darkMode ? (
                                                        <BsFillBrightnessHighFill
                                                            className={
                                                                "w-5 h-5"
                                                            }
                                                        />
                                                    ) : (
                                                        <BsFillMoonFill
                                                            className={
                                                                "w-5 h-5"
                                                            }
                                                        />
                                                    )}
                                                </button>
                                            );
                                        }}
                                    </ThemeToggler>
                                </div>
                                <a
                                    href={`https://wa.me/${site.phone} `}
                                    className="rounded px-4 py-2 text-primary hover:text-black/50 dark:hover:text-gray-200 focus:outline-none focus:ring-[1px] focus:ring-white focus:ring-offset-2"
                                    target={"_blank"}
                                >
                                    <span className="sr-only">Whatsapp</span>
                                    <FaPhoneAlt className="w-5 h-5" />
                                </a>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex md:hidden items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                <div className="flex justify-center items-center">
                                    <ThemeToggler>
                                        {({ theme, toggleTheme }) => (
                                            <button
                                                className="mx-4 text-primary dark:text-primary dark:hover:text-primary/50 hover:text-primary/50"
                                                type="button"
                                                onClick={() => {
                                                    toggleTheme(
                                                        darkMode
                                                            ? "light"
                                                            : "dark"
                                                    );
                                                    setDarkMode(!darkMode);
                                                }}
                                                aria-label="Toggle theme"
                                            >
                                                {!darkMode ? (
                                                    <BsFillBrightnessHighFill
                                                        className={"w-5 h-5"}
                                                    />
                                                ) : (
                                                    <BsFillMoonFill
                                                        className={"w-5 h-5"}
                                                    />
                                                )}
                                            </button>
                                        )}
                                    </ThemeToggler>
                                </div>
                                <a
                                    href={`https://wa.me/${site.phone}`}
                                    className="rounded text-primary py-2"
                                    target={"_blank"}
                                >
                                    <span className="sr-only">Whatsapp</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="w-5 h-5"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 px-2 pt-2 pb-3">
                            {navigation.map((item) => (
                                <Link
                                    key={item.name}
                                    onClick={close}
                                    to={item.link}
                                    className={classNames(
                                        item.current
                                            ? "bg-secondary text-primary"
                                            : "text-primary hover:bg-primary/50 hover:text-white dark:text-primary dark:hover:text-gray-200",
                                        "block px-3 py-2 rounded-md text-base font-medium capitalize"
                                    )}
                                    aria-current={
                                        item.current ? "page" : undefined
                                    }
                                >
                                    {item.name}
                                </Link>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}
