import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Logo2 from "../../images/Logo2.svg";
import { useSite } from "../../hooks/use-site";

const navigation = [
    { name: "services", link: "/services" },
    { name: "careers", link: "/careers" },
    { name: "contact", link: "/contact" },
    { name: "Privacy Policy", link: "/privacy-policy" },
];

export default function Footer() {
    const site = useSite();

    return (
        <footer className="relative overflow-hidden">
            <div
                aria-hidden="true"
                className="flex absolute -top-48 left-0 -z-[1]"
            >
                <div className="bg-slate-300 opacity-30 blur-3xl w-screen h-[600px] dark:bg-slate-800/60"></div>
                {/* <div class="absolute bg-slate-200 opacity-90 blur-3xl w-screen h-[300px] transform translate-y-32 dark:bg-slate-800/60"></div> */}
            </div>
            <div className="max-w-[85rem] pt-4 px-4 sm:px-6 lg:px-8 mx-auto">
                <div className="grid grid-cols-1 text-center md:text-left md:grid-cols-5 items-center gap-5 ">
                    <div className="col-span-1 flex justify-center items-center">
                        <Link
                            className=" max-w-max text-5xl font-semibold dark:text-white font-norican text-primary"
                            to="/"
                            aria-label="siliconConcepts."
                        >
                            <img
                                className="w-[150px] md:w-full"
                                src={Logo2}
                                alt=""
                            />
                        </Link>
                    </div>
                    <div className="hidden md:block md:col-span-3"></div>
                    <div className="col-span-1">
                        {/* <div className="-ml-[10px] mb-8">
                            <a
                                className="inline-flex justify-center items-center w-8 h-8 text-center text-gray-500 hover:bg-primary/30 hover:text-secondary rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition dark:text-gray-500 dark:hover:text-gray-200 dark:hover:bg-gray-800 dark:focus:ring-offset-slate-900"
                                href={site?.socialMediaLinks?.twitter || "#!"}
                                target={"_blank"}
                            >
                                <FaTwitter className="w-5 h-5" />
                            </a>
                            <a
                                className="inline-flex justify-center items-center w-8 h-8 text-center text-gray-500 hover:bg-primary/30 hover:text-secondary rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition dark:text-gray-500 dark:hover:text-gray-200 dark:hover:bg-gray-800 dark:focus:ring-offset-slate-900"
                                href={site?.socialMediaLinks?.linkedin || "#!"}
                                target={"_blank"}
                            >
                                <FaLinkedinIn className="w-5 h-5" />
                            </a>
                            <a
                                className="inline-flex justify-center items-center w-8 h-8 text-center text-gray-500 hover:bg-primary/30 hover:text-secondary rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition dark:text-gray-500 dark:hover:text-gray-200 dark:hover:bg-gray-800 dark:focus:ring-offset-slate-900"
                                href={site?.socialMediaLinks?.whatsapp || "#!"}
                                target={"_blank"}
                            >
                                <IoLogoWhatsapp className="w-5 h-5" />
                            </a>
                        </div> */}
                        {navigation.map((item) => (
                            <div className="my-3">
                                <Link
                                    className="text-sm text-gray-500 hover:text-gray-800 dark:text-gray-500 dark:hover:text-gray-200"
                                    to={item.link}
                                >
                                    <span className="capitalize">
                                        {" "}
                                        {item.name}{" "}
                                    </span>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <p className="text-center max-w-[85rem] pt-7 md:pt-0 pb-4 px-4 sm:px-6 lg:px-8 mx-auto text-secondary">
                © 2011 - 2024 &nbsp;
                <span className={"font-norican text-primary"}>
                    SiliconConcepts
                </span>{" "}
                &nbsp; all righs reserved.
            </p>
        </footer>
    );
}
